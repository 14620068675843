import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1 from "../../images/TIMBERLINE-L-SERIES-32-D-front-WEB-THUMB.jpg"
import Image2 from "../../images/TIMBERLINE-L-SERIES-32-D-front-side-WEB-THUMB.jpg"
import Image3 from "../../images/TIMBERLINE-L-SERIES-32-D-front-side-back-WEB-THUMB.jpg"
import Image4 from "../../images/TIMBERLINE-L-SERIES-32-D-front-side-back-twist-WEB-THUMB.jpg"

import Image1Large from "../../images/TIMBERLINE-L-SERIES-32-D-front-WEB-Large.jpg"
import Image2Large from "../../images/TIMBERLINE-L-SERIES-32-D-front-side-WEB-Large.jpg"
import Image3Large from "../../images/TIMBERLINE-L-SERIES-32-D-front-side-back-WEB-Large.jpg"
import Image4Large from "../../images/TIMBERLINE-L-SERIES-32-D-front-side-back-twist-WEB-Large.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const L32DSPage = () => {
  return (
    <>
      <SEO title="L32 Floor Scrubber| Timberline Cleaning Equipment" />
      <Helmet>
        <title>L32 Floor Scrubber| Timberline Cleaning Equipment</title>
        <description>
          Help make your cleaning operation more productive with a large
          cleaning path to reliably tackle even the toughest environments. The
          L32 provides performance and durability to get the job done faster
          time after time. The (2x) 16-inch (41 cm) disk style scrub brushes are
          installed and removed without tools to allow ease when switching
          between different brushes or pads, depending upon the application’s
          needs. The squeegee blades can be rotated or flipped without tools.
          Everything bolted to a steel frame ensures this machine will withstand
          daily application hardships.
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              image1={Image1}
              image2={Image2}
              image3={Image3}
              image4={Image4}
              image1Large={Image1Large}
              image2Large={Image2Large}
              image3Large={Image3Large}
              image4Large={Image4Large}
              mobileLink="#demo-section"
              mobileTitle="L32 Disk"
            />
            <ProductDescription
              productTitle="L32 Disk"
              subHeading="Walk-Behind Large Floor Scrubbers"
              modelNum="L32D"
              productDescription="Help make your cleaning operation more productive with a large cleaning path to reliably tackle even the toughest environments.  The L32 provides performance and durability to get the job done faster time after time.  The (2x) 16-inch (41 cm) disk style scrub brushes are installed and removed without tools to allow ease when switching between different brushes or pads, depending upon the application’s needs.  The squeegee blades can be rotated or flipped without tools.  Everything bolted to a steel frame ensures this machine will withstand daily application hardships."
              link="#demo-section"
              list1="Constructed robustly — steel frame construction and durable components "
              list2="Simplify maintenance with easy access to the battery and recovery tank"
              list3="Traction drive speed controlled with easy to use Drive Speed Control thumb dial"
              list4="Easily operate and maneuver with push button forward and toggle reverse"
              list5="Clean any hour of the day with approved sound levels < 67 dBA"
              list6="Increase productivity with over 3 hours of run time between dump and refill"
              list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
              brochure="../../timberline-walk-behind-sm.pdf"
              tech="../../tl-l-series-tech-specs-v21263.pdf"
              parts="https://www.dropbox.com/s/d4hfjc714ofn6x6/t-l-series-p-en.pdf?dl=0"
              operator="https://www.dropbox.com/s/fesp0mxj3v2ki7u/t-l-series-op-en.pdf?dl=0"
              hood="../../timberline-underthehood.pdf"
              pm="../../L-Series-PM.pdf"
              mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
              />
          </div>
          <div id="demo-section"></div>
          <IndexSection3 title="Request a Free Demo at your Facility" />
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default L32DSPage
